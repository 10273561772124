// colors
$white: #ffffff;
$aliceBlue: #f2f8ff;
$ligthGrey: #dddddd;
$darkGrey: #999999;
$grey: #707070;
$black: #222222;
$darkBlue: #1c98cc;
$orange: #fc9c27;
$red: #db0800;
$purple: #8e00c3;
$lightBlue: #3ac6fa;
$green: #00a81a;
$darkGreen: #4c6900;
$readonlyGrey: #f7f7f7;

// font-sizes
$size-xs: 12px;
$size-s: 14px;
$size-m: 16px;
$size-l: 18px;
$size-xl: 20px;
$size-xxl: 24px;

// font-weight;
$weight-xs: 400;
$weight-s: 500;
$weight-m: 600;
$weight-l: 700;

body {
  color: $black !important;
  background-color: aliceBlue;
  min-height: 101vh;
}
button,
textarea,
body,
.css-1wa3eu0-placeholder,
input {
  font-family: "Fira Sans", sans-serif !important;
}

label {
  font-size: $size-s;
  color: $darkGrey;
  font-weight: 400 !important;
}

.css-4ljt47-MenuList > div:hover,
.css-g1d714-ValueContainer:hover,
.css-1hb7zxy-IndicatorsContainer:hover,
button:hover,
.react-tabs__tab:hover {
  cursor: pointer !important;
}

.login-container {
  display: flex;
  justify-content: center;
}

.login-wrapper {
  padding: 40px 20px 40px 20px !important;
  flex-direction: column;
  text-align: center;
  width: 40%;
  display: flex;
  justify-content: center;
  margin-top: 100px !important;
  background-color: $white;
}

.navigation-bar {
  width: 100%;
  height: 85px;
  margin-left: 0px !important;
  border: 1px solid $ligthGrey;
  padding-right: 2% !important;
  background-color: $white !important;
}

.information-wrapper {
  background-color: $white;
}

.logo-wrapper img {
  height: 50px;
}

.navbar-item {
  color: $black;
  font-size: $size-m;
  font-weight: $weight-l;
  padding: 0px !important;
  height: 100%;
  flex-shrink: unset !important;
}

.is-active {
  color: $darkBlue !important;
  border-bottom: 2px solid $darkBlue;
}

.default-button {
  border: none !important;
}

.image-wrapper {
  width: 21% !important;
}

.navigation-column {
  padding-left: 15px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 0px !important;
}

.navigation-columns {
  width: 100%;
  height: 100%;
}

.navigation-container {
  display: flex !important;
  padding-bottom: 0px !important;
  height: 130px !important;
}

.navbar-item-container {
  justify-content: center;
  display: flex !important;
  height: 100%;
  max-width: 200px;
  padding: 0px 5px !important;
}

.product-information-container {
  margin: 20px 2% 20px 2%;
}

.form-group > label {
  position: relative;
  top: -12px;
  padding-left: 3px;
  z-index: 10;
  left: 21px;
  background-color: $white;
  padding-right: 3px;
}

.form-group > input,
.form-group > div > input,
.form-group > .css-2b097c-container {
  border: none !important;
  position: relative !important;
  box-shadow: none !important;
  top: -2px;
  height: 28px !important;
  // padding: 1px 8px 1px 8px;
}

.datepicker-wrapper {
  position: relative !important;
  max-height: 42px !important;
  height: 42px !important;
  // border: 1px solid #DDDDDD;
  background-color: white;
}

.datepicker-label {
  z-index: 800;
  top: -10px;
  font-family: "Fira Sans";
  position: relative;
  font-size: 14px;
  color: grey;
  background-color: white;
  padding-left: 0px !important;
  left: 10px;
}

.datepicker-component {
  position: relative !important;
  top: -20px;
}

.form-group > div {
  top: -18px !important;
  position: relative !important;
  height: 28px !important;
  padding: 1px 8px 1px 8px;
}

.form-group > input:focus {
  box-shadow: none;
}

.form-group {
  position: relative;
  max-height: 42px;
  height: 42px;
  border: 1px solid $ligthGrey;
  background-color: white;
}

.css-tlfecz-indicatorContainer {
  color: $darkBlue !important;
}

.main-container {
  padding: 1% 2% 1% 2% !important;
}

.css-1wa3eu0-placeholder {
  color: $grey !important;
  font-style: italic;
}

.react-tabs {
  margin-bottom: 60px !important;
}

.react-tabs__tab-list {
  display: flex;
  box-shadow: 1px solid $red;
  border-radius: 25px;
  padding: 0px 20px 0px 20px !important;
  background-color: $white;
  border-bottom: none !important;
  box-shadow: 0px 10px 10px #0053760d;
}

.react-tabs__tab {
  display: flex !important;
  height: 50px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  vertical-align: middle;
  margin: 0px !important;
  justify-content: center;
  font-weight: $weight-m;
  font-size: $size-s;
}

.react-tabs__tab-panel {
  padding: 40px 0px 40px 0px;
}

.react-tabs__tab-list > li {
  flex: 1 1 auto;
  text-align: center;
  margin: 5px;
  height: 100%;
}

.react-tabs__tab--selected {
  color: $darkBlue !important;
  border-bottom: 2px solid $darkBlue !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.product-image {
  max-height: 200px;
  width: 85%;
}

.product-image-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-right: 1px solid #dddddd;
}

.product-navigation-button-container {
  margin-left: 10px;
}

.product-navigation-button {
  width: 40px;
  background-color: $white;
  border: 1px solid $ligthGrey;
  margin: 0px 5px 0px 5px;
}

.navigation-button {
  background-color: $aliceBlue;
  border: none !important;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.navigation-button > div > span {
  margin-left: 12px;
  font-size: $size-m !important;
}
.navigation-button > div {
  padding: 0px 12px 0px 12px;
}

.main-data-column {
  background-color: $white;
  border-radius: 5px;
  margin-right: 22px;
  margin-bottom: 22px;
}

.main-data-column > span {
  position: relative;
  top: -20px;
  font-size: $size-s;
  color: $white;
  border-radius: 10px;
  padding: 2px 8px 2px 8px;
}

.content-container {
  padding: 0px 20px 0px 20px !important;
}

.ert-container {
  background-color: $white;
  border-radius: 10px;
  margin-right: 22px;
  height: 650px;
  padding: 15px 20px 15px 20px !important;
}

.ert-category-header {
  font-size: $size-m;
  font-weight: $weight-s;
}

.ert-header {
  text-align: center;
  font-size: $size-xxl;
  font-weight: $weight-s;
  color: $green;
}

.ert-label {
  font-size: $size-m;
}

.checkbox {
  display: flex !important;
  align-items: center;
}

.table {
  border-spacing: 0;
  background-color: aliceBlue !important;
  overflow-x: scroll;

  .thHeader{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //display: inline;
    margin-right: 5px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    color: $darkBlue !important;
    padding-top: 20px !important;
    font-weight: 600;
    //font-size: $size-s;
    padding: 0 10px 0 15px !important;
    //min-width: 85px !important;    
  }

  .tr {
    background-color: #ffffff;
    :last-child {
      .td {
        border-bottom: 0;
      }
    }    
  }

  .resizer {
    display: inline-block;
    background: #1c98cc;
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action:none;

    &.isResizing {
      background: red;
    }
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    font-size: $size-s;
    position: relative;

    .fa-chevron-up,
    .fa-chevron-down {
      position: absolute;
    }

    :last-child {
      border-right: 0;
    }

    
  }

  .th{
    border-bottom: 2px solid #1c98cc !important;
  }
}

.commentsap-item-container {
  background-color: $white;
  border-radius: 10px;
  flex-direction: column;
}

.commentsap-item-title {
  color: $darkBlue;
  font-size: $size-s;
  padding-bottom: 0px !important;
  font-weight: $weight-l;
}

textarea {
  height: 120px;
  width: 100%;
  border: $ligthGrey 1px solid;
  padding: 10px !important;
  font-size: $size-s;
  line-height: 1.5;
  overflow-y: scroll;
  resize: none;
}

textarea:focus {
  outline: none;
}

.table-container {
  overflow-x: auto !important;
  overflow-y: auto !important;
  border-radius: 10px;
  background-color: $white;
}

.tactical-parts-container {
  max-height: calc(100vh - 330px);
}

.product-dashboard-container {
  max-height: calc(100vh - 475px);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

div::-webkit-scrollbar-track {
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: radial-gradient(circle, #1c98cc 14%, #1c98cc 64%);
}

.commentsap-textarea-container ::-webkit-scrollbar-thumb {
  background: radial-gradient(circle, #1c98cc 14%, #1c98cc 64%);
  border-radius: 10px;
}

.commentsap-textarea-container ::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}

.filter-wrapper > input {
  padding-left: 6px !important;
  border-radius: 10px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  width: 100%;
  border: 1px solid #ddd !important;
}

.filter-wrapper {  
  bottom: 5px;
}

.filter-wrapper > input:focus {
  outline: none;
}

th {
  text-align: center;
  position: relative;
  background: $white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.label {
  padding-left: 10px !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.dropdownHeaderTitle {
  font-size: $size-m;
  font-weight: $weight-m;
}

.dropdownHeaderDescription {
  font-size: $size-m;
  color: $darkGrey;
}

.dropdownSubHeader {
  font-size: $size-s;
  color: $darkGrey;
}

.dropdownSubValue {
  font-size: $size-s;
}

.engine-type-table > tr {
  border-bottom: none !important;
}

.engine-type-table > tr > th {
  font-size: $size-xs !important;
  color: $darkGrey !important;
  font-weight: $weight-xs;
}

.engine-type-table > tr > td,
.engine-type-table > tr > th {
  padding: 5px 0px 5px 0px !important;
}

.product-container {
  .css-2b097c-container {
    max-width: 75%;
    min-width: 65%;
  }
}

.css-2b097c-container {
  width: 100%;
  border: 1px solid $ligthGrey;
}

.imageButton {
  background-color: $white !important;
  border: 1px solid $ligthGrey !important;
}

.css-1pahdxg-control {
  border: none !important;
  box-shadow: none !important;
}
.dropdown-container > .form-group > div > .css-1pahdxg-control {
  top: -5px !important;
}

.dropdown-container > .form-group > div > .css-yk16xz-control {
  top: -5px !important;
}

.css-yk16xz-control {
  border: none !important;
}

.css-26l3qy-menu {
  z-index: 20 !important;
}

.main-data-column > .columns > .column {
  padding: 10px 8px 10px 8px;
}

.modal-card {
  max-width: 1500px !important;
  background-color: $white;
  padding: 25px;
  overflow: auto;
}

.react-datepicker__input-container {
  top: -10px !important;
}

.react-datepicker__input-container > input {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.add-item-container {
  position: absolute;
  right: 100px;
}

.react-datepicker-popper {
  z-index: 20;
}

.unsaved-changes-wrapper {
  position: fixed;
  bottom: 0;
  height: 60px;
  background-color: $white;
  width: 100%;
  left: 0px;
  z-index: 800;
  border-top: 4px solid $red;
  text-align: right;
  padding-top: 10px;
  padding-right: 50px;
}

.save-button {
  padding: 7px 30px 7px 30px;
  border: 1px solid $red;
  background-color: $red;
  color: $white;
  border-radius: 3px;
}

.react-datepicker__month-container {
  z-index: 30;
}

.tactical-parts-button {
  padding: 7px 30px 7px 30px;
  background-color: $darkBlue;
  color: $white;
  border-radius: 3px;
  height: 40px;
  border: 0px none !important;
  width: 100%;
  font-size: $size-m;
}

.tactical-parts-form {
  height: 160px !important;
}

.description {
  font-size: $size-s;
  color: $darkGrey;
}

.unsaved-changes-wrapper > span {
  margin-right: 50px;
  font-style: italic;
  font-size: $size-s;
}

.tactical-parts-header {
  padding: 30px 2% 30px 2% !important;
  background-color: $white;
}

.dashboard-export-header {
  overflow-y: auto;

  background-color: $white;
}

.dashboard-export-add-row {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
  flex-direction: column;
  max-height: 300px;
  border-right: 1px solid gray;
  overflow-y: auto;
}

input[type="file"] {
  color: transparent;
}

.tactical-parts-header > .column > .columns {
  flex-direction: column;
}

.load-wrapper {
  height: 500px;
  padding-top: 200px;
}

.css-4ljt47-MenuList > div {
  color: $black;
}

.load-wrapper > div {
  text-align: center !important;
}

.gridview-footer {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.gridview-footer > .row {
  width: 100%;
}

.navigation-gridview-button {
  background-color: $darkBlue;
  color: #ffffff;
  border-radius: 3px;
  border: 0px none !important;
  margin-right: 5px;
  font-size: 16px;
}

// Media Queries
$small: 1400px;
$medium: 2000px;

@media screen and (max-width: $small) {
  .form-group > label {
    left: 5px !important;
  }
}

@media screen and (max-width: $medium) {
  .form-group > label {
    display: -webkit-box;
    height: 21px;
    display: inline-block;
    overflow: hidden;
    left: 7px !important;
  }
  .dropdown-container > .form-group > div > .css-1pahdxg-control {
    top: -10px !important;
  }
  .dropdown-container > .form-group > div > .css-yk16xz-control {
    top: -10px !important;
  }
}

.css-26l3qy-menu {
  z-index: 9000 !important;
}
